<script>
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import DrawerProfileSettings from '/~/templates/bill-payments/components/drawer/views/profile/drawer-profile.vue'

export default {
  name: 'profile-details',
  components: {
    DrawerProfileSettings,
  },
  setup() {
    const { isPasswordEditable, isUserAddressEnabled } = useProvider()
    const { translate } = useLocalization()

    return {
      isPasswordEditable,
      isUserAddressEnabled,
      translate,
    }
  },
  computed: {
    items() {
      return [
        {
          icon: 'v2/heroic/pencil-alt',
          label: 'Personal details',
          description: `Update your personal information${
            this.isUserAddressEnabled
              ? ` ${this.translate('common.and')} address`
              : ''
          }`,
          route: { hash: '#profile-personal-details' },
        },
        {
          icon: 'menu/lock-shield',
          label: 'Password',
          description: 'Manage your own secure password',
          hidden: !this.isPasswordEditable,
          route: { hash: '#profile-change-password' },
        },
      ]
    },
  },
}
</script>

<template>
  <drawer-profile-settings :items="items" to="menu-modal" />
</template>
